import { combineReducers } from 'redux';

import { SET_INIT_STATE } from './utils/actionType';
import { CONFIG_COUNTRY_MAPPING } from '../constants';

import config, { initialState as configInitialState } from './config';
import configActions from './actions/config';
import configSelectors from './selectors/config';
import {
  initHoldingHolesData,
  setBlockInstanceConfiguration,
} from './utils/config';

import app, { initialState as appInitialState } from './app';
import appActions from './actions/app';
import appSelectors from './selectors/app';

import ui, { initialState as uiInitialState } from './ui';
import uiActions from './actions/ui';
import uiSelectors from './selectors/ui';

import metadata, { initialState as metadataInitialState } from './metadata';
import metadataActions from './actions/metadata';
import metadataSelectors from './selectors/metadata';

import order, { initialState as orderInitialState } from './order';
import orderActions from './actions/order';
import orderSelectors from './selectors/order';

import threekit, { initialState as threekitInitialState } from './threekit';
import threekitActions from './actions/threekit';
import threekitSelectors from './selectors/threekit';

export default combineReducers({
  config,
  app,
  ui,
  metadata,
  order,
  threekit,
});

const setInitState = (state) => async (dispatch, getState) => {
  const threekitApi = state.threekit.threekitApi;
  if (!threekitApi) throw new Error(`threekitApi is required when initState!`);
  threekitApi.enableApi('store');
  threekitApi.enableApi('player');
  // threekitApi.enableApi('getLoadingProgress');
  window.threekitApi = threekitApi;
  window.metadata = state.metadata;
  window.getSnapshot = (opions) => dispatch(orderSelectors.getSnapshot(opions));
  const holding = await initHoldingHolesData(threekitApi);
  const annotationId = await threekitApi.scene.addNode(
    {
      name: 'annotation',
      type: 'Annotation',
      plugs: { Properties: [{ type: 'Default', visible: false }] },
    },
    threekitApi.instanceId
  );
  Object.assign(state, {
    config: Object.assign({}, state.config, { holding, annotationId }),
  });
  dispatch({ type: SET_INIT_STATE, payload: state });
  const { country, year, trackingNumber } = getState().app;
  setBlockInstanceConfiguration(threekitApi, {
    Year: year,
    Country: CONFIG_COUNTRY_MAPPING[country],
    'Tracking Number': trackingNumber,
  });

  const { viewCode, presetCode } = getState().order;
  if (viewCode) dispatch(orderActions.loadProductFromViewCode());
  else if (presetCode) dispatch(orderActions.loadProductFromPresetCode());
};

export const actions = {
  config: configActions,
  app: appActions,
  ui: uiActions,
  metadata: metadataActions,
  order: orderActions,
  threekit: threekitActions,
  setInitState,
};

export const selectors = {
  config: configSelectors,
  app: appSelectors,
  ui: uiSelectors,
  metadata: metadataSelectors,
  order: orderSelectors,
  threekit: threekitSelectors,
};

export const initialState = {
  config: configInitialState,
  app: appInitialState,
  ui: uiInitialState,
  metadata: metadataInitialState,
  order: orderInitialState,
  threekit: threekitInitialState,
};
